import constant from '../_config/constant'
import {
    authHeader,
    // logout,
    basicAuthHeader,
} from '../_helpers'

import { history } from '../_helpers/history'
import { handleResponse } from '../_helpers/utils'

export const propertyService = {
    list,
    allList,
    bookinglist,
    bookingDetail,
    add,
    update,
    updateStatus,
    getDetail,
    updatePhoto,
    _update,
    updateBookingStatus,
    saveAsDraft,
    reviewsList,
    updateBookingNoticePeriod,
    cancelBookingByHost,
    bookingRefundStatus,
    cancelBookingByDate,
    deleteDraft,
    isRoomDeletableService,
    blockUnblockedBeds,
    checkBedAvailablity,
    allActiveProperty
}

function list(data) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return fetch(`${constant.apiUrl}/property/list?search=${data.search}&page=${data.page}&limit=${data.limit}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function allList(data) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return fetch(`${constant.apiUrl}/property/host_wise`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function allActiveProperty(data) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return fetch(`${constant.apiUrl}/property/host_listed_property`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function updateStatus(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }
    return fetch(`${constant.apiUrl}/property/updateStatus`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function add(data) {
    var location = Object.values(data.location)
    var fd = new FormData()
    fd.append('aboutListing', data.aboutListing)
    fd.append('accomodationCapacity', data.accomodationCapacity)
    fd.append('address', data.address)
    fd.append('amenities', JSON.stringify(data.amenities))
    fd.append('bookingType', data.bookingType)
    fd.append('cancelationPolicy', data.cancelationPolicy)
    fd.append('cleaningFee', data.cleaningFee)
    fd.append('listingName', data.listingName)
    fd.append('parking', data.parking)
    fd.append('perMonthPrice', data.perMonthPrice)
    fd.append('perNightPrice', data.perNightPrice)
    fd.append('propertyType', data.propertyType)
    fd.append('rentalType', data.rentalType)
    fd.append('safety', JSON.stringify(data.safety))
    fd.append('securityDeposite', data.securityDeposite)
    fd.append('sleepingArrangements', JSON.stringify(data.sleepingArrangements))
    fd.append('transportation', data.transportation)
    fd.append('stayLimit', data.stayLimit ? data.stayLimit : '')
    fd.append('reservedDays', data.reservedDays ? data.reservedDays : '')
    fd.append('lineholderDays', data.lineholderDays ? data.lineholderDays : '')
    fd.append('lineholderDiscountType', data.lineholderDiscountType ? data.lineholderDiscountType : '')
    fd.append('lineholderDiscount', data.lineholderDiscount ? data.lineholderDiscount : '')
    fd.append('covidVerified', data.covidVerified)
    fd.append('location', JSON.stringify(location.reverse()))
    fd.append('extraChargePerGuest', data.extraChargePerGuest)
    fd.append('houseRules', JSON.stringify(data.houseRules))
    fd.append('apartmentNumber', data.apartmentNumber ? data.apartmentNumber : '')
    fd.append('propertyId', data.propertyId ? data.propertyId : '')
    fd.append('photosExist', 'photos' in data)

    // data.photos.forEach((file) => { fd.append('files', file.current); })

    var newFile = []
    var newFileOrder = []

    var oldFIle = []

    if (data.photos && data.photos.length) {
        data.photos.forEach(file => {
            if (file.type && file.type == 'url') {
                oldFIle.push(file)
            } else {
                newFileOrder.push(file.order)
                newFile.push(file)
            }
        })
    }

    newFile.length &&
        newFile.forEach(file => {
            fd.append('files', file.current)
        })
    fd.append('oldFIle', JSON.stringify(oldFIle))
    fd.append('newFileOrder', JSON.stringify(newFileOrder))
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: fd,
    }

    return fetch(`${constant.apiUrl}/property/add`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function update(data) {
    var location = Object.values(data.location)
    var fd = new FormData()
    fd.append('aboutListing', data.aboutListing)
    fd.append('accomodationCapacity', data.accomodationCapacity)
    fd.append('address', data.address)
    fd.append('amenities', JSON.stringify(data.amenities))
    fd.append('bookingType', data.bookingType)
    fd.append('cancelationPolicy', data.cancelationPolicy)
    fd.append('cleaningFee', data.cleaningFee)
    fd.append('houseRules', JSON.stringify(data.houseRules))
    fd.append('listingName', data.listingName)
    fd.append('parking', data.parking)
    fd.append('perMonthPrice', data.perMonthPrice)
    fd.append('perNightPrice', data.perNightPrice)
    fd.append('propertyType', data.propertyType)
    fd.append('rentalType', data.rentalType)
    fd.append('safety', JSON.stringify(data.safety))
    fd.append('securityDeposite', data.securityDeposite)
    fd.append('sleepingArrangements', JSON.stringify(data.sleepingArrangements))
    fd.append('transportation', data.transportation)
    fd.append('stayLimit', data.stayLimit ? data.stayLimit : '')
    fd.append('reservedDays', data.reservedDays ? data.reservedDays : '')
    fd.append('lineholderDays', data.lineholderDays ? data.lineholderDays : '')
    fd.append('lineholderDiscountType', data.lineholderDiscountType ? data.lineholderDiscountType : '')
    fd.append('lineholderDiscount', data.lineholderDiscount ? data.lineholderDiscount : '')
    fd.append('covidVerified', data.covidVerified)
    fd.append('location', JSON.stringify(location.reverse()))
    fd.append('extraChargePerGuest', data.extraChargePerGuest)
    fd.append('propertyId', data.propertyId)

    var newFile = []
    var newFileOrder = []

    var oldFIle = []

    if (data.photos && data.photos.length) {
        data.photos.forEach(file => {
            if (file.type && file.type == 'url') {
                oldFIle.push(file)
            } else {
                newFileOrder.push(file.order)
                newFile.push(file)
            }
        })
    }

    newFile.length &&
        newFile.forEach(file => {
            fd.append('files', file.current)
        })
    fd.append('oldFIle', JSON.stringify(oldFIle))
    fd.append('newFileOrder', JSON.stringify(newFileOrder))

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: fd,
    }
    return fetch(`${constant.apiUrl}/property/update`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function saveAsDraft(data) {
    var location = data.location ? Object.values(data.location) : []
    var fd = new FormData()

    fd.append('aboutListing', data.aboutListing ? data.aboutListing : '')
    fd.append('accomodationCapacity', data.accomodationCapacity ? data.accomodationCapacity : '')
    fd.append('address', data.address ? data.address : '')
    fd.append('bookingType', data.bookingType ? data.bookingType : '')
    fd.append('cancelationPolicy', data.cancelationPolicy ? data.cancelationPolicy : '')
    fd.append('cleaningFee', data.cleaningFee ? data.cleaningFee : '')
    fd.append('listingName', data.listingName ? data.listingName : '')
    fd.append('parking', data.parking ? data.parking : '')
    fd.append('perMonthPrice', data.perMonthPrice ? data.perMonthPrice : '')
    fd.append('perNightPrice', data.perNightPrice ? data.perNightPrice : '')
    fd.append('propertyType', data.propertyType ? data.propertyType : '')
    fd.append('rentalType', data.rentalType ? data.rentalType : '')
    fd.append('securityDeposite', data.securityDeposite ? data.securityDeposite : '')
    fd.append('transportation', data.transportation ? data.transportation : '')
    fd.append('stayLimit', data.stayLimit ? data.stayLimit : '')
    fd.append('reservedDays', data.reservedDays ? data.reservedDays : '')
    fd.append('lineholderDays', data.lineholderDays ? data.lineholderDays : '')
    fd.append('lineholderDiscountType', data.lineholderDiscountType ? data.lineholderDiscountType : '')
    fd.append('lineholderDiscount', data.lineholderDiscount ? data.lineholderDiscount : '')
    fd.append('covidVerified', data.covidVerified ? data.covidVerified : '')
    fd.append('extraChargePerGuest', data.extraChargePerGuest ? data.extraChargePerGuest : '')
    fd.append('propertyId', data.propertyId ? data.propertyId : '')
    fd.append('apartmentNumber', data.apartmentNumber ? data.apartmentNumber : '')
    fd.append('photosExist', 'photos' in data)

    if (data.sleepingArrangements && data.sleepingArrangements.length) {
        fd.append('sleepingArrangements', JSON.stringify(data.sleepingArrangements))
    }
    if (location && location.length) {
        // fd.append("location", JSON.stringify(location));
        fd.append('location', JSON.stringify(location.reverse()))
    }
    if (data.safety && data.safety.length) {
        fd.append('safety', JSON.stringify(data.safety))
    }
    if (data.houseRules && data.houseRules.length) {
        fd.append('houseRules', JSON.stringify(data.houseRules))
    }
    if (data.amenities && data.amenities.length) {
        fd.append('amenities', JSON.stringify(data.amenities))
    }

    var newFile = []
    var newFileOrder = []
    var oldFIle = []

    if (data.photos && data.photos.length) {
        data.photos.forEach(file => {
            if (file.type && file.type == 'url') {
                oldFIle.push(file)
            } else {
                newFileOrder.push(file.order)
                newFile.push(file)
            }
        })
    }

    newFile.length &&
        newFile.forEach(file => {
            fd.append('files', file.current)
        })

    fd.append('oldFIle', JSON.stringify(oldFIle))
    fd.append('newFileOrder', JSON.stringify(newFileOrder))

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: fd,
    }
    return fetch(`${constant.apiUrl}/property/saveasdraft`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function _update(data) {
    if (data.location) {
        data.location = data.location.reverse()
    }
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    }

    return fetch(`${constant.apiUrl}/property/_update`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function isRoomDeletableService(roomId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ roomId }),
    }

    return fetch(`${constant.apiUrl}/property/is_room_deletable`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function updatePhoto(data) {
    var fd = new FormData()
    fd.append('propertyId', data.propertyId)
    var newFile = []
    var newFileOrder = []
    var oldFIle = []

    if (data.photos && data.photos.length) {
        data.photos.forEach(file => {
            if (file.type && file.type == 'url') {
                oldFIle.push(file)
            } else {
                newFileOrder.push(file.order)
                newFile.push(file)
            }
        })
    }

    newFile.length &&
        newFile.forEach(file => {
            fd.append('files', file.current)
        })
    fd.append('oldFIle', JSON.stringify(oldFIle))
    fd.append('newFileOrder', JSON.stringify(newFileOrder))
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: fd,
    }
    return fetch(`${constant.apiUrl}/property/update/photo`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function getDetail(id, options) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }

    let URL = `${constant.apiUrl}/property/detail/${id}`

    if (options && options.checkIn) {
        URL = `${constant.apiUrl}/property/detail/${id}?&checkIn=${options.checkIn}`
    }

    if (options && options.checkOut) {
        URL = `${constant.apiUrl}/property/detail/${id}?&checkOut=${options.checkOut}`
    }

    if (options && options.checkOut && options.checkIn) {
        URL = `${constant.apiUrl}/property/detail/${id}?&checkIn=${options.checkIn}&checkOut=${options.checkOut}`
    }

    if (options && options.bookingType) {
        URL = `${constant.apiUrl}/property/detail/${id}?&bookingType=${options.bookingType}`
    }

    return fetch(URL, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function getPropertyDetails(id, option) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return fetch(`${constant.apiUrl}/property/${id}/detail?&checkIn=${option.checkIn}&checkOut=${option.checkOut}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function bookinglist(query) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return fetch(`${constant.apiUrl}/bookings?${new URLSearchParams(query).toString()}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function bookingDetail(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return fetch(`${constant.apiUrl}/booking/${id}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function updateBookingStatus({ id, status , reason = null  }) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ bookingId: id, status , reason }),
    }
    return fetch(`${constant.apiUrl}/booking/updateStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function reviewsList() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }
    return fetch(`${constant.apiUrl}/review/list`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function updateBookingNoticePeriod({ bookingId, checkOutDate = new Date(), userId, isAppliedByHost }) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ bookingId, checkOutDate, userId, isAppliedByHost }),
    }

    return fetch(`${constant.apiUrl}/booking/update/notice`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function cancelBookingByHost({ bookingId   , isNotice}) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ bookingId  , isNotice}),
    }
    console.log(requestOptions , "requestOptions");
    return fetch(`${constant.apiUrl}/booking/cancelBookingByDate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}


function deleteDraft({ propertyId }) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ propertyId }),
    }
    return fetch(`${constant.apiUrl}/property/delete_draft`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function bookingRefundStatus({ bookingId }) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    }
    return fetch(`${constant.apiUrl}/refund/${bookingId}`, requestOptions)
        .then(handleResponse)
        .then(refundData => {
            return refundData
        })
}

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);

//     if (data.statusCode == 401 || data.statusCode == 403) {
//       logout();
//       window.location.reload();
//     }
//     if (data.statusCode != 200) {
//       const error = (data && data.userMessage) || response.statusText;
//       return Promise.reject(error);
//     }
//     return data;
//   });
// }

function cancelBookingByDate(bookingId, cancelDate, isCancelByAdmin) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ bookingId, cancelDate, isCancelByAdmin }),
    }
    return fetch(`${constant.apiUrl}/booking/cancelBookingByDate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function blockUnblockedBeds(blockUnblockDetails) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ blockUnblockDetails }),
    }
    return fetch(`${constant.apiUrl}/property/bed-availability`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

function checkBedAvailablity(details) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ details }),
    }
    return fetch(`${constant.apiUrl}/property/check/bed-availability`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}
