import { AppConfig } from '../Config'

const constant = {
    TOKEN_KEY: 'hut',
    DATA_KEY: 'hud',
    BASIC_AUTH: {
        USERNAME: 'dummy',
        PASSWORD: 'dummy@12345',
    },
    // googleAPI_KEY: "AIzaSyCryqdJrYXn4RAZ6LU_kC-uYB2Tn2K00_M",
    googleAPI_KEY: AppConfig.googleAPI_KEY,
    GOOGLE_CLIENTID: AppConfig.GOOGLE_CLIENTID,
    FACEBOOK_APPID: '237438817209817',
    lat: 10.99835602,
    lng: 77.01502627,
    TEMP_REF: 'tempRef',

    // ------------ Local -------------------------//
    // apiUrl: 'http://localhost:4500/api/v1/user',
    // mediaUrl: 'http://localhost:4500/media/',
    // USER_WEB_URL:"http://localhost:3000",
    // persona_mode: 'sandbox',
    // persona_template_id: 'itmpl_Ygs16MKTkA6obnF8C3Rb17dm'

    // development server - newly created on 22-08-2023
    apiUrl: AppConfig.apiUrl,
    mediaUrl: AppConfig.mediaUrl,
    USER_WEB_URL: AppConfig.USER_WEB_URL,
    persona_mode: AppConfig.persona_mode,
    persona_template_id: AppConfig.persona_template_id,

    // ------------ development server - newly added on 11-01-2022 -------------------------//
    // apiUrl: "http://35.90.236.251:4500/api/v1/user",
    // mediaUrl: "http://35.90.236.251:4500/media/",
    // USER_WEB_URL: "http://35.90.236.251:4400",
    // persona_mode: "sandbox",
    // persona_template_id: "itmpl_dfRJkeBSrdtJL925ehrPSC8t",

    // // ------------ try biz4 local -------------------------//
    // apiUrl: "https://a726-182-78-194-242.ngrok.io/api/v1",
    // mediaUrl: "http://35.85.155.38:4500/media/",
    // // USER_WEB_URL: "http://35.85.155.38:4400",
    // USER_WEB_URL: "http://localhost:3000",
    // persona_mode: "sandbox",
    // persona_template_id: "itmpl_Ygs16MKTkA6obnF8C3Rb17dm",

    // ------------ staging -------------------------//
    // apiUrl: "http://35.85.155.38:4500/api/v1/user",
    // mediaUrl: "http://35.85.155.38:4500/media/",
    // USER_WEB_URL: "http://35.85.155.38:4400",
    // // USER_WEB_URL: "http://localhost:3001",
    // persona_mode: "sandbox",
    // persona_template_id: "itmpl_Ygs16MKTkA6obnF8C3Rb17dm",

    // ------------ Production -------------------------//
    // apiUrl: "https://api.crewmatesapp.com/api/v1/user",
    // mediaUrl: "https://api.crewmatesapp.com/media/",
    // USER_WEB_URL: "https://crewmatesapp.com",
    // persona_mode: "production",
    // persona_template_id: "itmpl_dfRJkeBSrdtJL925ehrPSC8t",
}

export default constant
