import React, { useRef, useEffect, useState } from "react";
import Navbar from "./Navbar";
import TopBar from "./topbar";
import Footer from "./../layout/footer";
import { FiChevronDown } from "react-icons/fi";
import Modal from "./ReactModal";

import { getFaqCategory, getFaqList } from "./../_services/index";
import { toast } from "react-toastify";

function Faq() {
  const [requesting, setRequesting] = useState(true);
  const [activeSection, setActiveSection] = useState(1);
  const [open, setOpen] = useState(-1);
  const [category, setCategory] = useState(0);
  const [faqs, setFaqs] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [activeFaqList, setActiveFaqList] = useState([]);
  const [fetchingFaqs, setfetchingFaqs] = useState(true);

  const HandleAccordian = (index, func, curr) => {
    if (index === curr) {
      func(-1);
    } else {
      func(index);
    }
  };
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = '//js-na1.hs-scripts.com/46216140.js';

    // Append script to the head of the document
    document.head.appendChild(script);

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array ensures this runs only once after initial mount

  const renderAccordian = () => {
    return activeFaqList.map((curr, index) => {
      return (
        <div
          key={index}
          className={`tw-my-b ${
            index !== faqs.length - 1 ? " tw-border-b" : ""
          } border-gray-300`}
          onClick={() => HandleAccordian(index, setOpen, open)}
        >
          <div className="tw-flex tw-items-center tw-cursor-pointer tw-justify-between">
            <h4 className=" tw-text-base lg:tw-text-lg tw-font-medium md:tw-font-semibold">
              {curr.question}
            </h4>

            <span className="tw-text-white">
              {open !== index ? (
                <FiChevronDown
                  size={30}
                  className="tw-ml-4  tw-flex-shrink-0 tw-text-black "
                />
              ) : (
                <FiChevronDown
                  size={30}
                  className="tw-ml-4 tw-transform tw-rotate-180 tw-flex-shrink-0 tw-text-black "
                />
              )}
            </span>
          </div>
          <p
            type="header-caption"
            className={`tw-text-sm md:tw-text-base tw-text-primary-blue-dim tw-px-3 tw-transition-all tw-duration-300 tw-ease-in-out tw-overflow-hidden ${
              index === open
                ? "tw-visible tw-opacity-1 tw-p-3"
                : "tw-h-0 tw-opacity-0 tw-invisible"
            }`}
          >
            {`${curr.answer}`}
          </p>
        </div>
      );
    });
  };

  function handleCategorySelection(id) {
    setCategory(id);
    setActiveFaqList(faqs.filter((el) => el.categoryId === id));
  }

  useEffect(() => {
    const fetchFaq = async () => {
      try {
        const faqList = await getFaqList();
        const categoryList = await getFaqCategory();
        console.log(
          faqList.data,
          categoryList.data,
          "hola",
          faqList.data.status
        );
        if (faqList.data.status) {
          setfetchingFaqs(false);
          setFaqs(faqList.data.data);

          const hostCategoryList = categoryList.data.data.filter(f=>f.userType==='Host');
          setCategoryList(hostCategoryList);

          let _activeFaqList = [];
          if (hostCategoryList.length > 0) {
            setCategory(hostCategoryList[0].id);
            _activeFaqList = faqList.data.data.filter(
              (el) =>
                el.categoryId === hostCategoryList[0].id &&
                el.userType === "Host"
            );
          }

          setActiveFaqList(_activeFaqList);
          console.log({ hostCategoryList, _activeFaqList });

          setRequesting(false);
        } else {
          console.log("wrong block");
          toast.error("Check you internet connection and try again!");
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong!!");
      }
      setRequesting(false);
      setfetchingFaqs(false);
    };
    fetchFaq();
  }, []);

  return (
    <div id="main-wrapper">
      <TopBar title="FAQ" />
      <div className="deznav">
        <div className="deznav-scroll">
          <Navbar />
        </div>
      </div>

      <div className="content-body">
        <div className="container-fluid">
          {/* {fetchingFaqs && (
            <Modal>
              <div className="tw-bg-white tw-rounded tw-shadow-md tw-p-4">
                <div className="loader"></div>
              </div>
            </Modal>
          )} */}
          {requesting && (
            <div className="tw-my-10 tw-flex tw-items-center tw-justify-center">
              <div className="bg-white tw-w-max rounded shadow-md p-4">
                <div className="loader"></div>
              </div>
            </div>
          )}
          {!requesting && (
            <div className="row">
              <h3 className=" tw-text-3xl tw-text-center  md:tw-text-5xl tw-text-primary tw-font-bold lg:tw-font-extrabold ">
                Hello, how can we help?
              </h3>
              <div className="tw-flex md:tw-w-max tw-mx-auto tw-flex-nowrap tw-overflow-x-scroll tw-py-4 tw-px-1 tw-scrollbar-hide  tw-items-center tw-my-4  tw-text-primary">
                {categoryList.map((el) => (
                  <div
                    key={el.id}
                    onClick={() => handleCategorySelection(el.id)}
                    className={`tw-mr-2 lg:tw-mr-4 tw-flex tw-items-center tw-justify-center tw-flex-shrink-0 hover:tw-bg-primary tw-h-32  tw-w-36 ${
                      category === el.id
                        ? "tw-bg-primary tw-text-white "
                        : "tw-bg-white tw-text-primary"
                    } hover:tw-text-white  tw-cursor-pointer tw-flex-col  tw-rounded-xl tw-ring-1 tw-ring-primary-blue-light`}
                  >
                    <p className="tw-text-sm tw-text-center tw-px-2  lg:tw-text-base tw-font-medium tw-capitalize">
                      {`${el.name}`}
                    </p>
                  </div>
                ))}
              </div>
              <div className="tw-my-10 ">
                <div className="md:tw-w-6/12 md:tw-mx-auto">
                  <h4 className="tw-text-xl lg:tw-text-2xl tw-text-center  tw-text-primary tw-font-medium tw-mb-4 ">
                    Frequently Asked Question
                  </h4>
                  <div className="tw-text-primary">{renderAccordian()}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
    
  );
}

export default Faq;
